import { motion, useAnimation } from "framer-motion";
import React, { useState, useRef } from "react";
import { useIntl } from "react-intl";
import { MdClose, MdOutlineWhatsapp } from "react-icons/md";
import styles from "./styles.module.scss";

const contacts = [
  {
    title: "Norte e Nordeste",
    phone: "5551993872616",
  },
  {
    title: "Centro Oeste",
    phone: "5551993872616",
  },
  {
    title: "Sudeste",
    phone: "5511956598384",
  },
  {
    title: "Sul",
    phone: "5551993872616",
  },
];

function Whatsapp() {
  const intl = useIntl();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const sliderControls = useAnimation();

  const controls = useAnimation();

  const handleMouseEnter = () => {
    controls.start({ opacity: 1 });
  };

  const handleMouseLeave = () => {
    controls.start({ opacity: 0 });
  };

  const handleOnClick = () => {
    isOpen
      ? sliderControls.start({ opacity: 0, y: 10, scale: 0.9 })
      : sliderControls.start({ opacity: 1, y: 0, scale: 1 });
  };

  return (
    <>
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 0, scale: 0.9 }}
        animate={sliderControls}
        transition={{ duration: 0.3, delay: 0.3 }}
        className={`${styles.modal}`}
      >
        <div className={styles.content}>
          <MdClose
            color="#fff"
            size={20}
            className={styles.icon}
            onClick={() => {
              setIsOpen(false);
              handleOnClick();
            }}
          />
          <h1>
            Selecione sua região. <br />
            Iremos te encaminhar para o consultor mais próximo de você.
          </h1>
          <div className={styles.column}>
            {contacts.map((contact, key) => (
              <a
                key={key}
                className={styles.button}
                href={`https://wa.me/${contact.phone}`}
                target="_blank"
                rel="noreferrer"
                aria-label="Whatsapp"
              >
                {contact.title} <MdOutlineWhatsapp color="#fff" size={15} />
              </a>
            ))}
          </div>
        </div>
      </motion.div>
      <motion.div
        className={styles.container}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          setIsOpen(!isOpen);
          handleOnClick();
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="37"
          height="36"
          viewBox="0 0 37 36"
          fill="none"
        >
          <path
            d="M18.4823 2.99982C10.2233 2.99982 3.49995 9.71738 3.49695 17.9764C3.49545 20.6164 4.18692 23.1937 5.49792 25.4647L3.46472 32.9998L11.3134 31.1453C13.5019 32.3393 15.9655 32.9661 18.4735 32.9676H18.4794C26.7369 32.9676 33.4573 26.2485 33.4618 17.991C33.4648 13.9875 31.908 10.2234 29.079 7.39142C26.25 4.56092 22.4903 3.00132 18.4823 2.99982ZM18.4794 5.99982C21.6834 6.00132 24.6944 7.25051 26.9579 9.51251C29.2214 11.7775 30.4648 14.7871 30.4618 17.9881C30.4588 24.5941 25.0854 29.9676 18.4764 29.9676C16.4769 29.9661 14.4963 29.464 12.7518 28.5115L11.7411 27.9608L10.6249 28.2244L7.67175 28.9217L8.39246 26.244L8.71765 25.0428L8.09656 23.9647C7.04956 22.1527 6.49545 20.0809 6.49695 17.9764C6.49995 11.3734 11.8749 5.99982 18.4794 5.99982ZM13.1796 11.0623C12.9291 11.0623 12.524 11.1561 12.1805 11.5311C11.837 11.9046 10.868 12.8092 10.868 14.6512C10.868 16.4932 12.2098 18.2737 12.3973 18.5242C12.5833 18.7732 14.9873 22.6727 18.7928 24.1727C21.9548 25.4192 22.5971 25.1732 23.2841 25.1102C23.9711 25.0487 25.5004 24.2064 25.8124 23.3319C26.1244 22.4573 26.1251 21.7051 26.0321 21.5506C25.9391 21.3946 25.6893 21.3016 25.3143 21.1141C24.9408 20.9266 23.1002 20.0228 22.7567 19.8983C22.4132 19.7738 22.162 19.7108 21.913 20.0858C21.664 20.4608 20.9484 21.3016 20.7294 21.5506C20.5104 21.8011 20.2928 21.8348 19.9178 21.6473C19.5428 21.4583 18.3371 21.0619 16.9061 19.7869C15.7931 18.7954 15.0421 17.5721 14.8231 17.1971C14.6056 16.8236 14.8026 16.6184 14.9901 16.4324C15.1581 16.2644 15.3622 15.9952 15.5497 15.7762C15.7357 15.5572 15.8002 15.4012 15.9247 15.1522C16.0492 14.9032 15.9855 14.6834 15.8925 14.4959C15.7995 14.3084 15.0712 12.4596 14.7382 11.7186C14.4577 11.0961 14.1614 11.0816 13.8944 11.0711C13.6769 11.0621 13.4286 11.0623 13.1796 11.0623Z"
            fill="white"
          />
        </svg>

        <motion.p
          initial={{ opacity: 0 }}
          animate={controls}
          exit={{ opacity: 0 }}
        >
          {intl.formatMessage({
            id: "whatsapp_message",
          })}
        </motion.p>
      </motion.div>
    </>
  );
}

export default Whatsapp;
